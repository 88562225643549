.wrapper {
  width: 100%;
  height: auto;

  .section {
    max-width: 62.5%;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border-radius: 5px;
    margin-bottom: 20px;

    .section_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;

      h3 {
        white-space: nowrap;
      }

      .line {
        flex-grow: 1;
        border-bottom: 1px solid #c4c4c4;
      }
    }

    .table_container {
      width: 100%;
      display: flex;

      .details_table {
        min-width: 500px;
        justify-content: center;
      }
    }

    .details_table {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 50px;
      margin: 0 auto;
      margin-bottom: 70px;
      padding: 0 20px;
      max-width: 1300px;
      justify-content: center;

      .column {
        flex: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 40px;

        .column_header {
          font-weight: bolder;
          font-size: 18px;
          margin-bottom: 10px;
          text-align: center;
        }

        .rows {
          display: flex;
          flex-direction: column;
          flex: 1;
          gap: 40px;
          text-align: center;

          .row {
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            position: relative;
            color: red;

            .key {
              flex: 1;
              font-weight: bolder;


            }

            .key_realign {
              flex: 0;
            }

            .value {
              flex: 1;
            }
          }
        }

        .rows_dimensions {
          display: flex;
          flex-direction: column;
          flex: 1;
          gap: 40px;
          text-align: center;

          .row {
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;

            position: relative;

            .key {
              flex: 1;
              font-weight: bolder;
              text-align: center;
            }

            .key_realign {
              flex: 0;
            }

            .value {
              flex: 1;
            }
          }
        }
      }
    }

    .table {
      width: 100%;
      margin: 0 auto;
      border-collapse: collapse;
      border-style: hidden;
      font-size: smaller;
      overflow: auto;

      .tr {
        padding: 0.6rem;
        text-align: center;
        border: 1px solid rgb(183, 148, 148);
      }

      .td {
        padding: 0.6rem;
        border: 1px solid rgb(183, 148, 148);
        text-align: center;
      }

      .th {
        font-size: 1rem;
        padding: 0.6rem;
        border-bottom: 1px solid rgb(160, 160, 160);
      }
    }
  }

  .seperator {
    border-left: 1px solid rgb(180, 180, 180);
  }

  .footer {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;

    .btns_container {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 30px;

      .action_btn {
        outline: none;
        border: none;
        background-color: #1c1d42;
        padding: 10px 15px;
        color: beige;
        border-radius: 5px;

        &:hover {
          cursor: pointer;
          background-color: #2c2d52;
        }
      }
    }
  }

  .pagination_container {
    display: flex;
    justify-content: center;

    >* {
      padding: 10px;
      width: 1.8rem;
      height: 1.8rem;
      font-size: small;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgb(61, 61, 61);
      border: #1c1d42ba solid 1px;
    }
  }
}
.pos_absolute_z_index{
    position: absolute;
    z-index: 9999;
    background: white;
    width: 100%;
    top: 155px;
    padding: 0px;
}

.green_value {
  color: green;
  font-weight: 700;
}

@media screen and (max-width: 1130px) {
  .wrapper {
    .seperator {
      display: none;
    }
  }
}

@media screen and (max-width: 468px) {
  .wrapper {

    .section {
      max-width: 100%;

      .section_header {
        flex-direction: column;
        gap: 0;

        .line {
          display: none;
        }
      }

      .table_container {
        flex-direction: column;

        .details_table {
          min-width: 100%;
          justify-content: center;
        }
      }

      .details_table {
        gap: 20px;

        .column {
          max-width: 100%;
          flex: 1;
          flex-direction: column;
          gap: 20px;

          .column_header {
            font-weight: bolder;
            font-size: 18px;
            margin-bottom: 10px;
            text-align: center;
          }

          .rows {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .row {
              white-space: nowrap;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 10px;

              .key {
                font-weight: bolder;
              }
            }
          }

          .rows_dimensions {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .row {
              white-space: nowrap;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 10px;

              .key {
                font-weight: bolder;
              }
            }
          }
        }
      }
    }

    .seperator {
      display: none;
    }
  }
}

.flex_main {
  display: flex;
  flex-direction: column;

  .line {
    flex-grow: 1;
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 30px;
  }
}

.flexify {
  display: flex;
  justify-content: center;
  align-items: center;
}

._padding {
  padding: 0.6rem;
}

.image_container {
  flex: 1;

  img {
    max-width: 100%;
  }
}

.pdf_only {
  display: none;
}

.whatever_is_hidden{
  display: none;
}
.name_input{
  margin-right: 0px !important;
  width: 273px !important;
}

@media print {
  .whatever_is_printed_willbe_hidden{
    display: none;
  }
  .ownership_topRelated{
    position: relative;
    top: 70px;
  }
  .pizza_header_bars{
      transform: translate(0px, -50px);
  }
  .bar_form{
      transform: translate(0px, -99px);
  }
  .pizza_bars{
      transform: translate(0px, -120px);
  }
  .breakdown_bars{
      max-height: 400px;
      transform: translate(0px, -130px);
  }
  .details_table{
    margin-bottom: 20px !important;
  }
  .scaled{
    transform: scale(0.8);
  }
  .performance_topRelated{
      position: relative;
      top: -240px;
      max-height: 600px;
  }
  .basicInfo_topRelated{
      position: relative;
      top: -70px;
  }
  .weights_topRelated{
      position: relative;
      top: -50px;
      max-height: 500px;
  }
  .page_break {
    page-break-before: always;
  }

  .pdf_hidden {
    display: none;
  }

  .pdf_only {
    display: block;
  }

  .pdf_accidentData_text {
    font-size: 1.3rem;
    text-align: center;
  }

  .wrapper {
    .section {
      max-width: 100%;
      margin: 0 auto !important;

      .line_chart {
        width: 100%;
        height: 100%;
      }

      box-shadow: none;

      .pdf_hidden {
        display: none;
      }

      .section_header {
        flex-direction: column;
        gap: 0;
        font-size: 24px;

        .line {
          display: flex;
        }
      }

      .table_container {
        flex-direction: column;

        .details_table {
          min-width: 100%;
          justify-content: center;
        }
      }

      .details_table {
        gap: 20px;

        .column {
          max-width: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 20px;
          font-size: larger;

          .column_header {
            font-weight: bolder;
            font-size: 18px;
            margin-bottom: 10px;
            text-align: center;
            white-space: nowrap;
          }

          .rows {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .row {
              white-space: nowrap;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 10px;

              .key {
                font-weight: bolder;
              }
            }
          }
        }
      }
    }
  }
}