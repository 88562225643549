.main_title {
  font-size: 2rem;
  font-weight: 700;
}

.secondary_title {
  font-size: 1.5rem;
}

.change_text {
  font-size: 1.15rem;
  gap: 1rem;
}

.change_img {
  height: 0.9rem;
}


@media (min-width: 450px) {
  .main_title {
    font-size: 3.5rem;
    font-weight: 700;
  }
  
  .secondary_title {
    font-size: 2.5rem;
  }
  
  .change_text {
    font-size: 1.5rem;
    gap: 1rem;
  }

  .change_img {
    height: 0.8rem;
  }
}


@media (min-width: 768px) {
  .main_title {
   font-size: 1.45rem;
  }

  .secondary_title {
    font-size: 1rem;
  }

  .change_text {
    font-size: 0.9rem;
    gap: 0.35rem;
  }

  .change_img {
    height: 0rem;
  }
}

@media (min-width: 950px) {
  .main_title {
   font-size: 1.75rem;
  }

  .secondary_title {
    font-size: 1.2rem;
  }

  .change_text {
    font-size: 1rem;
    gap: 0.5rem
  }

  .change_img {
    height: 0.7rem;
  }
}

@media (min-width: 1130px) {
  .main_title {
   font-size: 2.25rem;
  }

  .secondary_title {
    font-size: 1.35rem;
  }

  .change_text {
    font-size: 1rem;
    gap: 0.6rem;
  }
}

@media (min-width: 1350px) {
  .main_title {
   font-size: 2.5rem;
  }

  .secondary_title {
    font-size: 1.5rem;
  }

  .change_text {
    font-size: 1rem;
    gap: 0.8rem;
  }
}

@media (min-width: 1500px) {
  .main_title {
   font-size: 2.75rem;
  }

  .secondary_title {
    font-size: 2rem;
  }

  .change_text {
    font-size: 1.25rem;
    gap: 1rem;
  }

  .change_img {
    height: 1rem;
  }
 }

@media (min-width: 768px) {
  
}