.notFound_section{
    max-width: fit-content;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    .contain_image{
        width: 20%;
        height: auto;
        @media (max-width: 768px) {
            width: 50%;
        }
        margin: 0 auto  ;
        img{
            width: 100%;
            height: 100%;
        }
    }
    a{
        text-decoration: none;
        color: #000;
        font-size: 1.5rem;
        font-weight: 600;
        margin-top: 1rem;
        &:hover{
            color: rgb(25, 87, 255);
        }
    }
}