.form {
    .search {
        input {
            max-width: 120px;
            height: 30px;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-shadow: 0px 0px 2px 0px rgba(99, 99, 99, 0.75);
            padding: 0 5px;
            outline: none;

            &:focus {
                border: 1px solid rgb(139, 139, 139);
            }
        }
    }
}

.green_value {
    color: green;
    font-weight: 700;
}

// global styles for compare aircraft page*
.range_configs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    .map_inputs {
        display: flex;
        gap: 10px;
        flex-direction: column;
        align-items: center;

        .pax_slider {
            width: 170px;
            align-items: start !important;
            text-align: left !important;
        }

        label {
            display: grid;
            place-items: center;
            max-width: 200px;
            margin: 0 auto;
        }

    }
}


.compare_table {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    scrollbar-width: none;

    .compare_table_column {

        display: flex;
        flex-direction: column;
        flex: 1;
        font-size: 1rem;

        .compare_table_column_cell {
            padding: 20px;
            border-bottom: 1px solid #dedfe1;
            white-space: nowrap;
            text-align: center;
        }

        .invisible {
            visibility: hidden;
        }

        .table_key {
            font-weight: bolder;
            text-align: start;
            white-space: nowrap;
        }

        .table_column_head {
            font-weight: bolder;
            text-align: center;
            white-space: nowrap;
        }

        .remove_btn {
            border-radius: 15px;
            outline: none;
            border: none;
            background-color: #c4c4c4;
            padding: 5px 15px;
            font-weight: bolder;
            cursor: pointer;

            &:hover {
                background-color: #a4a4a4;
            }
        }
    }
}

.compare_table::-webkit-scrollbar {
    display: none;
}

.no_scroll{
    overflow: hidden;
}
@media print {
    input{
        max-width: 100px !important;
    }
    .compare_table{
        overflow: hidden;
    }
}
.btn {
    @apply bg-cetaceanBlue rounded-full outline-none border-0 text-white;
    padding: 5px 15px;
    cursor: pointer;
}


.chart_dates {
    width: 50%;
    margin-bottom: 15px;
    text-align: center;
    margin-left: 25%;
    display: flex;
    flex-wrap: wrap;
    gap: 50px
}

.single_select {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 15px;
    width: 30%;
}




// Ownership Costs 
.ownership_container {

    .additional_info {

        .key_value_bar {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 10px;
            padding-bottom: 10px;

            .key_value {
                flex: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                white-space: nowrap;

                input {
                    max-width: 120px;
                    height: 30px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    box-shadow: 0px 0px 2px 0px rgba(99, 99, 99, 0.75);
                    padding: 0 5px;
                    outline: none;
                
                    &:focus {
                      border: 1px solid rgb(139, 139, 139);
                    }
                }
            
                .fixed_span_long {
                width: 200px;
                }
            
                .fixed_span_medium {
                width: 130px;
                }
                
                .fixed_span_short {
                width: 70px;
                }
            
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
                }
            }

            .bar {
                flex: 2;
                background-color: rgb(230, 228, 229);
                height: 15px;
                transition: width 1s ease-in-out;
                text-align: end;
                color: beige;
                padding-right: 10px;
                position: relative;
                border-radius: 15px;

                .bar__fill1 {
                    background-color: rgba(255, 99, 132, 1);
                    height: 15px;
                    transition: width 1s ease-in-out;
                    text-align: end;
                    color: beige;
                    // width: 30%;
                    padding-right: 10px;
                    position: relative;
                    border-radius: 15px;
                    max-width: 100%;
                }

                .bar__fill2 {
                    background-color: rgba(54, 162, 235, 1);
                    height: 15px;
                    transition: width 1s ease-in-out;
                    text-align: end;
                    color: beige;
                    // width: 30%;
                    padding-right: 10px;
                    position: relative;
                    border-radius: 15px;
                    max-width: 100%;
                }

                .bar__fill3 {
                    background-color: rgba(255, 206, 86, 1);
                    height: 15px;
                    transition: width 1s ease-in-out;
                    text-align: end;
                    color: beige;
                    // width: 30%;
                    padding-right: 10px;
                    position: relative;
                    border-radius: 15px;
                    max-width: 100%;
                }

                .bar__fill4 {
                    background-color: rgba(75, 192, 192, 1);
                    height: 15px;
                    transition: width 1s ease-in-out;
                    text-align: end;
                    color: beige;
                    // width: 30%;
                    padding-right: 10px;
                    position: relative;
                    border-radius: 15px;
                    max-width: 100%;
                }

                .bar__fill5 {
                    background-color: rgba(75, 192, 192, 1);
                    height: 15px;
                    transition: width 1s ease-in-out;
                    text-align: end;
                    color: beige;
                    // width: 30%;
                    padding-right: 10px;
                    position: relative;
                    border-radius: 15px;
                    max-width: 100%;
                }

                .bar__fill6 {
                    background-color: rgba(153, 102, 255, 1);
                    height: 15px;
                    transition: width 1s ease-in-out;
                    text-align: end;
                    color: beige;
                    // width: 30%;
                    padding-right: 10px;
                    position: relative;
                    border-radius: 15px;
                    max-width: 100%;
                }

                .bar__fill8 {
                    background-color: rgb(199, 55, 158);
                    height: 15px;
                    transition: width 1s ease-in-out;
                    text-align: end;
                    color: beige;
                    // width: 30%;
                    padding-right: 10px;
                    position: relative;
                    border-radius: 15px;
                    max-width: 100%;
                }

                .bar__fill7 {
                    background-color: rgba(255, 159, 64, 1);
                    height: 15px;
                    transition: width 1s ease-in-out;
                    text-align: end;
                    color: beige;
                    // width: 30%;
                    padding-right: 10px;
                    position: relative;
                    border-radius: 15px;
                    max-width: 100%;
                }

            }
        }
    }
}

.reset_button {
    margin-top:30px;
    background-color: #fff;
    border: 2px solid #e1e1e1;
    border-radius: 10px;
    color: #23262f;
    outline: none;  
    padding: 10px 15px 10px 15px;
  }
  
  .reset_button:hover {
    background-color: #f7f7f7;
    cursor: pointer;
    border: 2px solid #787E8E;
  }

// pdf view for ownership costs
@media print {
    .reset_values{
        display: none;
    }
    .bar {
        background-color: rgb(230, 228, 229) s;
        print-color-adjust: exact;
      }
}



.sorting {

    right: 0;
    padding-top: 20px;

    input {
        max-width: 100px;
        height: 30px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0px 0px 2px 0px rgba(99, 99, 99, 0.75);
        padding: 0 5px;
        outline: none;

        &:focus {
            border: 1px solid rgb(139, 139, 139);
        }
    }

    .dropdown {
        .dropdown {
            width: 10rem;
        }
    }
}

.aquisition_container {
    .future_value {
        @apply flex justify-center items-center gap-5;
    }
}


@media print {
input{
    width: 100px !important;
}
.sectionAircraft{
    max-height: 670px;
}
    .compare_table {
        .compare_table_column {
            font-size: 1.2rem;
        }
    }

    .button {
        display: none;
    }
}

.squares {
    text-align: center;
    font-size: 12px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 20%;
    margin-top: 15px;

    .square0 {
        height: 18px;
        width: 40px;
        background-color: #ff000058;
        border: solid 2px #FF0000;
        margin-right: 10px;
    }

    .square1 {
        height: 18px;
        width: 40px;
        background-color: #0000ff42;
        border: solid 2px #0000FF;
        margin-right: 10px;
        margin-left: 30px;
    }

    .square2 {
        height: 18px;
        width: 40px;
        background-color: #ffff0047;
        border: solid 2px #FFFF00;
        margin-right: 10px;
        margin-left: 30px;
    }
}