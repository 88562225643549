.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100dvw;
  box-shadow: 0px 28px 56px -20px #00000040;
  z-index: 10;
  display: flex;
  align-items: start;
}

.sidenav {
  z-index: 99999;
  background: #FFFFFF;
  box-shadow: 0px 28px 56px -20px #00000040;
}

@media (max-width: 767px) {
  .sidenav {
    width: 100vw;
  }
}

.blocker {
  width: 300px;
}

.nav {
  background-color: #FFFFFF;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  z-index: 10;
}

.nav_item {
  display: flex;
  align-items: center;
  gap: 2rem;

  a, p {
    font-size: 18px;
  }
}

.sidenav_item_icon {
  background: #F4F5F5;
  border-radius: 12px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9E9FA4;
  
  i {    
    font-size: 20px;
  }
}

.active, .sidenav_item:hover {
  .sidenav_item_icon {
    color: #f9f9f9;
    background: #28ace2;
  }
}

.divider {
  height: 2px;
  width: 100%;
  background: #F3F5F9;
  margin-top: auto;
}

.sub_item {
  display: flex;
  align-items: center;
  gap: 0.75rem;

  i {
    color: #C8C8C8; 
  }
}

.status_icon {
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 999px;
  background: #3AB18B;
}

.menu_button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: #C8C8C8;
  font-size: 36px;
}

.nav_logo {
  height: 50px;
}