.dropdown {
    position: relative;
    z-index: 3;

    &.active {
        z-index: 10;

        .head {
            .arrow {
                svg {
                    transform: rotate(180deg);
                }
            }
        }

        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        }
    }

    .head {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        transition: box-shadow 0.2s;
        @apply relative h-[38px] text-sm font-medium border border-lightSilver flex items-center pr-12 pl-4 rounded-full cursor-pointer;

        &:hover {
            box-shadow: inset 0 0 0 2px var(--neutrals4);
        }
    }

    .header_dropdown {
        position: relative;
        height: 38px;
        width: 12rem;
        display: flex;
        align-items: center;
        padding: 0 48px 0 16px;
        border-radius: 12px;
        box-shadow: inset 0 0 0 2px var(--neutrals6);
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        transition: box-shadow 0.2s;

        &:hover {
            box-shadow: inset 0 0 0 2px var(--neutrals4);
        }
    }

    .head_menu {
        position: relative;
        height: 38px;
        width: 12rem;
        display: flex;
        align-items: center;
        padding: 0 48px 0 16px;
        border-radius: 12px;
        box-shadow: inset 0 0 0 2px var(--neutrals6);
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        transition: box-shadow 0.2s;

        &:hover {
            box-shadow: inset 0 0 0 2px var(--neutrals4);
        }
    }

    .selection {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .arrow {
        transform: translateY(-50%);
        @apply absolute top-1/2 right-2 size-8 flex justify-center items-center rounded-full cursor-pointer;

        svg {
            fill: var(--neutrals4);
            transition: transform 0.2s;
        }
    }

    .body {
        position: absolute;
        top: calc(100% + 2px);
        left: 0;
        right: 0;
        padding: 8px 0;
        border-radius: 12px;
        background: var(--neutrals8);
        box-shadow: inset 0 0 0 2px var(--neutrals6),
            0 4px 12px rgba(var(--neutrals2), 0.1);
        visibility: hidden;
        opacity: 0;
        transform: translateY(3px);
        transition: all 0.2s;
        max-height: 250px;
overflow: hidden;
overflow-y: scroll;
    }

    .option {
        padding: 8px 16px;
        font-size: 14px;
        font-weight: 500;
        transition: color 0.2s;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        &:hover,
        &.selectioned {
            color: var(--blue);
        }
    }
}

@media screen and (max-width: 768px) {
  .dropdown {
    .header_dropdown {
        width: 100%;
    }
  }
}