.circle_chart {
  display: block;
  margin: 10px auto;
  max-width: 100%;
  max-height: 250px;
}

.circular_chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle_bg {
  fill: none;
  stroke: #eee;
}

.circle {
  fill: none;
  strokeLinecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    strokeDasharray: 0 100;
  }
}

.percentage {
  font-size: 0.5em;
  text-anchor: middle;
  font-weight: 500;
}

.title {
  text-transform: uppercase;
  color: #B6B8BD;
  text-align: center;
}
