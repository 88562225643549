// a simple loading spinner 
.spinner{
    animation: spin 1s linear infinite;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #0c2f47;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin: 0 auto;
    display: block;
    position: relative;
    z-index: 9999;
}
    @keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    }

