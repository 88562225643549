@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html, body, #root{
  @apply size-full
}
@layer base {
  .blurData{
    @apply relative z-0
  }
  .blurData::after, .blurData::before {
    content: "";
    @apply absolute top-0 left-0 
  }
  .blurData::after {
    z-index: 5;
    backdrop-filter: blur(5px);
    @apply size-full
  }
  .blurData::before {
    background-image: url('../assets/padlock.png');
    transform: translate(-50%, -50%);
    @apply size-5 bg-no-repeat bg-contain top-1/2 left-1/2 z-10
  }
}

.main-content {
  height: calc(100vh - 70px);
}

@media screen and (min-width: 500px) {
  .card {
    padding: 2rem;
  }
}

.card {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 1.25rem;
  box-shadow: 0px 28px 56px -20px #00000040;

  h3 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

.dropdown-menu {
  position: absolute;
  right: 0;
  left: auto;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-top: 15px;
  padding: 1rem!important;

  .options-list {
    max-height: 175px;
    overflow-y: scroll;
    margin-top: 5px;
  }
}

.interact_button {
  position: relative;
  cursor: pointer;
}

.flight_history_30_days {
  display:none!important;
}

.recharts-bar-rectangle {
  fill-opacity: 1 !important;
}

.recharts-bar-rectangle:hover {
  fill: none !important;
}

.no-padding {
  padding: 0px!important;
}

.change {
  border-radius: 12px;
  border: 2px solid;
  padding: 0.25rem;
  font-weight: 300;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-width: 115px;
  justify-content: center;

  img {
    height: 0.8rem;
  }
}

.re_change{
  @apply font-semibold p-1 flex items-center justify-center min-w-28 gap-1;
  img {
    height: 0.8rem;
  }
}
.chart_card {
  min-height: 400px;
}

.positiveChange {
  border-color: #70FD63;
  color: #00811C;
  background-color: #BEFDB8;
}

.negativeChange {
  border-color: #FD6F6F;
  color: #930101;
  background-color: #FFB1B1;
}


.re_positiveChange {
  color: #00811C;
}

.re_negativeChange {
  color: #930101;
}
.alert {
  border-color: #DFD14C;
  background-color: #F6E653C4;
}

.ai_beta_tag {
  border-color: #4C2FFF;
  color: #1800AD;
  background-color: #907EFF;
  font-size: 1rem!important;
  padding: 0.25rem 0.5rem!important;
}

.interact_button {
  box-shadow: 0px 21px 19px -9px #0000000D;
  border: 1px solid #EBECF2;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 12px;
  padding: 0.5rem 1.5rem!important;
  cursor: pointer;
  justify-content: center;

  i {
    color: #B2B5BA;
  }

  p {
    margin: 0px;
    font-weight: 300;
  }
}

.table-count {
  color: #8000FF!important;
}
.re_table-count {
  @apply text-CharlestonGreen font-semibold;
}

.view_more {
  text-align: center;
  color: #8000FF!important;
  width: 100%;
}

.chart_bg {
  flex-grow: 1;
  background: url("../../public/top_routes.png");
  border-radius: 12px;
  background-repeat: no-repeat;
  background-size: cover;
}

.filter_input {
  box-shadow: 0px 2px 18.399999618530273px -9px #00000026;
  border: 1px solid #DCDEE0;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 12px;
  padding: 0.5rem 1.5rem!important;
}

.cpp_shadow {
  box-shadow: 0px 28px 56px -20px #00000040;
}

.cpp_table {
  table-layout: auto;
  text-align: center;
  border-collapse: collapse;

  thead {
    text-transform: uppercase;
    color: #B6B8BD;
    background: #FAFAFC;
    z-index: 4;
  }
  th {
    padding: 1rem;
  }
  td {
    text-align: center;
    padding: 0.5rem 1rem;
  }

  .first-col-left {
    tr {
      td:first-of-type, th:first-of-type {
        padding-left: 2rem;
        text-align: left;
      }
    }
  }

  tbody {
    
    tr {
      border-bottom: 1px solid #dedfe1
    }
  
    tr:last-of-type {
      border-bottom: none;
    }
  }
}

.re_cpp_table {
  table-layout: auto;
  text-align: center;
  border-collapse: collapse;

  thead {
    @apply uppercase text-CharlestonGreen z-10 bg-cultured;
  }
  th {
    @apply first:rounded-l-full last:rounded-r-full p-4;
  }

  td {
    @apply py-2 px-4 text-center font-semibold;
  }
  .re_interact_button{
    @apply bg-CharlestonGreen text-white font-normal border-0;
  }
  .first-col-left {
    tr {
      td:first-of-type, th:first-of-type {
        padding-left: 2rem;
        text-align: left;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #dedfe1;

      td:first-child, th:first-child {
        padding-left: 2rem;
        text-align: left;
      }
    }
    
    tr:last-of-type {
      border-bottom: none;
    }
  }
}

.aircraft_lookup_input {
  width: 100%;
  max-width: 500px;
  font-size: 2rem;
  text-transform: none;
  text-align: center;
  border: 1px solid #EBECF2;
  box-shadow: 0px 21px 19px -9px #0000000D;
  border-radius: 12px;
  padding: 0.5rem;
}

.route_input {
  width: 100%;
  max-width: 400px;
  font-size: 1.5rem;
  text-transform: none;
  text-align: center;
  border: 1px solid #EBECF2;
  box-shadow: 0px 21px 19px -9px #0000000D;
  border-radius: 12px;
  padding: 0.5rem;
}

.live-flights-container {
  height: calc(100vh - 150px);
  max-width: 100%;
  overflow-x: scroll;
  max-height: 800px;
}

.modal-container {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.aircraft-compare-remove {
  position: absolute;
  top: -12px;
  right: -12px;
  border: 2px solid #05072c;
  background: white;
  border-radius: 999px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.chart-container {
  height: 100%;
}

.map-container {
  height: 100%;
  max-height: 85vh;
}

.map-height {
  height: calc(100vh - 150px);
  max-height: 300px;
}

.nav-item:hover {
  color: #2286b0!important;
}

.nav-item-active {
  color: #2286b0!important;
  font-weight: 600;
}

.news-card {
  flex-grow: 0;
  max-height: 50vh;
}

.force-static {
  position: static!important;
}

.calendar-item {
  min-height: 135px;
  p {
    display: none;
  }
}

.calendar-item:hover {
  p {
    display: block;
  }
}

@media screen and (min-width: 500px) {
  .map-height {
    height: calc(100vh - 300px);
    max-height: 600px;
  }

  .news-card {
    flex-grow: 1;
    max-height: unset;
  }
}

.comparison-modal {
  max-width: 90vw;
}

@media screen and (min-width: 900px){
  .comparison-modal {
    max-width: 800px;
  }
}

@media screen and (min-width: 1024px) {
  .live-flights-container {
    height: calc(100vh - 310px);
  }

  .all-aircraft-card {
    width: 100%;
    max-width: calc(40% - 2rem);
    max-height: 514px;
  }

  .overview-left-side {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: 0px 28px 56.20000076293945px -20px #00000040;
  }

  .map-height {
    height: 100%;
    max-height: 100%;
  }

  .activity-by-type-card {
    max-height: 65vh;
  }

  .map-container {
    height: 100%;
    max-height: 60vh;
  } 
}

.filter-active {
  background-color: #538ef645;
  border-color: #94bbfb;
}

.universal-chart-select-val {
  background-color:white;
}

.acq-output-val {
  @apply text-greenPigment;
  font-weight:bold;
}

.annual-cost-custom-out {
  font-size:30px;
}

.own-cost-output {
  @apply text-greenPigment;
  font-weight: bold;
}

@layer utilities {
  .scrollbar-size::-webkit-scrollbar {
    height: 8px;
  }
}

.heading-large  {
    @apply text-[32px] lg:text-7xl font-semibold;
}

.heading-medium  {
    @apply text-[30px] lg:text-[64px] font-semibold leading-none;
}

.re_interact_button {
  @apply border-2 border-lightSilver flex justify-center items-center gap-4 rounded-full py-1 px-4 cursor-pointer max-sm:text-sm;

  p {
    @apply text-base font-medium;
  }
}

.limit_cotent_width{
  @apply *:w-full *:max-w-[1700px] *:min-[1700px]:mx-auto;
}