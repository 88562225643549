.card {
  display: flex;
  flex-direction: column;
  padding: 10px;

  .preview {
    position: relative;
    overflow: hidden;
    display: grid;
    place-items: center;

    img {
      border-radius: 16px;
      max-width: 100%;
      width: 300px;
      min-height: 200px;
      max-height: 250px;
      margin: 0 auto;
      object-fit: cover;
    }
  }

  .category {
    position: absolute;
    top: 11px;
    left: 8px;
    color: var(--neutrals7);
    text-shadow: 0 0 1px var(--neutrals3);
  }

  .button {
    position: absolute;
    left: 50%;
    bottom: 16px;
    transform: translateX(-50%);
  }

  .link {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px 0;
    color: var(--neutrals2);
  }

  .body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .line {
    display: flex;
    align-items: flex-start;

    &:first-child {
      margin-bottom: 12px;
    }

    &:nth-child(2) {
      margin-top: auto;
    }
  }

  .title {
    margin-right: auto;
    padding-top: 1px;
    font-size: 16px;
    line-height: calc(24 / 16);
    font-weight: 500;
  }

  .price {
    flex-shrink: 0;
    margin-left: 8px;
    padding: 0 8px;
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px var(--green);
    font-size: 12px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--green);
  }

  .users {
    display: flex;
    margin-right: auto;
  }

  .avatar {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid var(--neutrals8);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;

      &:not(:first-child) {
        margin-left: -8px;
      }
    }
  }

  .foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid var(--neutrals6);
    font-size: 12px;
    line-height: calc(20 / 12);
    color: var(--neutrals4);
  }

  .status {
    font-weight: 600;
    line-height: 22px;
    font-size: 16px;
  }

  .count {
    font-weight: 500;
    line-height: 18px;
    font-size: 14px;
    margin-top: 0.5rem
  }
}